import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Footer from "../components/footer";
import GoogleTiles from "../components/ads/googleTiles";
import {
    Box,
    Container,
    Text,
    Stack,
} from '@chakra-ui/react';

const EndOfGallery = () => {

    const layoutData1 = useStaticQuery(graphql`
        query headerQueryAndHeaderQueryAndHeaderQueryAndHeaderQueryAndHeaderQueryAndHeaderQueryAndHeaderQueryAndHeaderQuery {
          site {
            siteMetadata {
              description
              siteUrl
              title
              logo
            }
          }  
        }
    `);

    const siteData = layoutData1.site.siteMetadata;
    const siteLogo = `/logos/${siteData.logo}`;

    return (
        <Box p={4}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                <Box mx="auto">
                    <img src={siteLogo} alt={siteData.title} width={200} />

                </Box>
            </Stack>
            <Container maxW={'6xl'} mt={10}>
                <h1 color={'gray.600'} fontSize={'30'}>
                    More Content You Might Like...
                </h1>
                <div style="width:100%;min-height:90px;">
                    <GoogleTiles slot={2505742826} layout={"-6z+ex+e-5b+7u"} key={Math.random()}/>
               </div>

              
            </Container>
            <Footer site={siteData}/>
        </Box>
    );
};

export default EndOfGallery;
